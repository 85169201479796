<script setup>
import Text from "~/components/atoms/Text.vue";
import BookIcon from "~/components/icons/BookIcon.vue";
import Image from "~/components/atoms/Image.vue";
import Modal from "~/components/molecules/Modal.vue";
import CatalogForm from "~/components/molecules/CatalogForm.vue";
import {useCookie} from "nuxt/app";
import {useCustomFetch} from '~/composables/customFetch.js';

const props = defineProps({
  id: String,
  image: String,
  url: String,
  short_name: String,
  name: String
})

const {image, url, id, short_name, name} = props
const showModal = ref(false);

const cookie = useCookie('catalog')

const handleClick = () => {
  if (cookie.value) {
    window.open(url, '_blank')
    return
  }

  showModal.value = true;
}

const submit = (form) => {
  useCustomFetch(`/catalog/${id}`, {
    method: 'POST',
    body: form
  }, true).then((response) => {
    showModal.value = false;

    const url = response.catalog
    window.open(url, '_blank')

    cookie.value = 1
  })

  // axios.post(`${runtimeConfigs.public.API_URL}/catalog/${id}`, form, {
  //   withCredentials: true,
  // }).then((response) => {
  //   showModal.value = false;
  //
  // const url = response.data.catalog
  //
  // window.open(url, '_blank')
  // })
}

</script>

<template>
  <div class="catalog g-8" @click="handleClick">
    <div class="image">
      <div class="preview">
        <Image :src="image"/>
      </div>
      <div class="hover">
        Open {{ short_name }}
        <BookIcon/>
      </div>
    </div>
    <Text class="name">{{ name }}</Text>

    <Modal v-if="showModal" @close="showModal=false" :height="450">
      <template #title>{{ $t('catalogs.form.title') }}</template>

      <Text>{{ $t('catalogs.form.description') }}</Text>
      <br>
      <CatalogForm @submit="submit"/>
      <br>
      <Text>{{ $t('catalogs.form.footer.0') }}
        <nuxt-link to="/faq/gdpr/">{{ $t('catalogs.form.footer.1') }}</nuxt-link>
        .
      </Text>
    </Modal>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/mixins.scss";

.catalog {
  cursor: pointer;
}

a {
  text-decoration: underline;
}

svg {
  stroke: $white;
}

.name {
  font-size: 12px;
  color: $black;
}

.image {
  width: 100%;
  position: relative;
  box-shadow: 0 4px 25px 0 #e5e5e5;
}

.preview {
  width: 100%;
  aspect-ratio: 1 / 1.42;
}

.hover {
  opacity: 0;
  border: 1px solid $black;
  background: linear-gradient(0deg, rgba(24, 24, 31, .8), rgba(24, 24, 31, .8));
  transition: opacity .4s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 4px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: .84px;

  @include smd {
    display: none;
  }

  &:hover {
    opacity: 1;
  }
}
</style>
